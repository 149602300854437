//https://medium.com/@avinashvazratkar446022/how-to-fetch-all-collectibles-from-phantom-wallet-connected-to-solana-network-62dffb70f26b

//Code to connect to phantom wallet using @solana/web3.js
import { Connection, clusterApiUrl, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { getParsedNftAccountsByOwner, isValidSolanaAddress, createConnectionConfig, } from "@nfteyez/sol-rayz";
//import axios from "axios";
import { func } from "superstruct";

window.SetWalletAddress = SetWalletAddress;
window.GetNftTokenDataDirect = GetNftTokenDataDirect;

var walletAddress = "";

//create a connection of devnet
const createConnection = () => {
    return new Connection(clusterApiUrl("devnet"));
};

//Code to get the owner address of your phantom wallet.
//check solana on window. This is useful to fetch address of your wallet.
const getProvider = () => {
    if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
            return provider;
        }
    }
};

///Backend Example
/*
//Function to get all NFT information.
//get NFT
const getAllNftData = async () => {
    try {
        if (connectData === true) {
            const connect = createConnectionConfig(clusterApiUrl("devnet"));
            const provider = getProvider();
            let ownerToken = provider.publicKey;
            const result = isValidSolanaAddress(ownerToken);
            console.log("result", result);
            const nfts = await getParsedNftAccountsByOwner({
                publicAddress: ownerToken,
                connection: connect,
                serialization: true,
            });
            return nfts;
        }
    } catch (error) {
        console.log(error);
    }
};

//Function to get all nft data
const getNftTokenData = async () => {
    try {
        let nftData = await getAllNftData();
        var data = Object.keys(nftData).map((key) => nftData[key]); let arr = [];
        let n = data.length;
        for (let i = 0; i < n; i++) {
            console.log(data[i].data.uri);
            let val = await axios.get(data[i].data.uri);
            arr.push(val);
        }
        return arr;
    } catch (error) {
        console.log(error);
    }
};
*/

///Backend Used
const getAllNftDataDirect = async () => {
    try {
        const connect = createConnectionConfig(clusterApiUrl("devnet"));
        //const provider = getProvider();
        let ownerToken = walletAddress;
        const result = isValidSolanaAddress(ownerToken);
        //console.log("result", result);
        const nfts = await getParsedNftAccountsByOwner({
            publicAddress: ownerToken,
            connection: connect,
            serialization: true,
        });
        return nfts;
    } catch (error) {
        console.log(error);
    }
};

//Function to get all nft data
const getNftTokenDataDirect = async () => {
    try {
        let nftData = await getAllNftDataDirect();
        var data = Object.keys(nftData).map((key) => nftData[key]);
        let arr = [];
        let n = data.length;
        for (let i = 0; i < n; i++) {
            //console.log(data[i].data);

            //let val = await axios.get(data[i].data.uri);
            //arr.push(val);

            var parsed = data[i].data.name.substring(0, data[i].data.name.indexOf(' '));
            arr.push(parsed);
            console.log("name[" + i + "]: " + data[i].data.name + " ** parsed(" + parsed+")");
        }

        window.unityInstance.SendMessage('WalletBackend', 'OnCollectiblesReceived', arr.join());
        return arr;
    } catch (error) {
        console.log(error);
    }
};

///Unity Function
function SetWalletAddress(m_walletAddress) {
    walletAddress = m_walletAddress;
    console.log("SetWalletAddress Web Done");
}

function GetNftTokenDataDirect() {
    getNftTokenDataDirect();
    console.log("GetNftTokenDataDirect Web Done");
}

///Debug
//SetWalletAddress("AqY8WJKCZLDqu4tNHpmuoYbs84Qvd4tikoKxrm9xkbP4");
//GetNftTokenDataDirect();

console.log("Wallet Ready!");